<template>

  <div class="kl">
      <iframe
      :src="path"
      width="100%"
      height="100%"
      ref="iframe"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import emitter from '../../tools/bus'
export default {
  name: 'K',
  props: ['id'],
  data () {
    return {
      path: ''
    }
  },
  methods: {
  },
  created () {
    //  console.log("./static/index.html?ids=" + this.id)
    this.path = './static/index.html?ids=' + this.id
    // console.log(this.path)
    // console.log(this.path)
    // this.path = './static/index.html?' + path + '&' + wsscheme;
  },
  watch: {
    id (val) {
      this.path = './static/index.html?ids=' + val
      // console.log(this.path)
    }
  },
  mounted () {
    emitter.on('KLineListId', (data) => {
      console.log('收到id')
      // console.log(data)
      this.path = './static/index.html?ids=' + data
    })
  },
  setup () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.kl{
  // display: block;
  width: 100%;
  height: 100%;
  // width: 1000px;
  // height: 1000px;
  // border: 1px solid red;
}
</style>
