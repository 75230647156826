<template>
  <div>
    <k-line :id="1"></k-line>
  </div>
</template>

<script>
import KLine from '../../components/kline/KLine.vue'
export default {
  components: { KLine },
  name: 'TestKLine',
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  setup () {
    return {

    }
  }
}
</script>

<style  lang="scss" scoped>

</style>
